<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Role Name"
          :helpText="'Provide a question of the name'"
          required
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          v-model="form.description"
          :rules="descriptionRules"
          label="Description"
          :helpText="'Please provide a description for a new role'"
          :textarea="true"
          required
        ></HelpFormInputVue>

        <SelectorGUIAppsVue
          dark
          autoload
          label="Allowed Apps"
          helpText="This is a set of apps that are allowed under this role. <br> IF NOTHING SELECTED ALL ALLOWED"
          return-object
          v-model="form.apps"
          multiple
        >
        </SelectorGUIAppsVue>

        <SelectorGUIViewsVue
          :disabled="!(form.apps && form.apps.length)"
          :filterApps="form.apps"
          dark
          autoload
          label="Allowed Views"
          helpText="This is a set of views that are allowed under this role. <br> IF NOTHING SELECTED ALL ALLOWED"
          return-object
          v-model="form.views"
          multiple
        >
        </SelectorGUIViewsVue>

        <SelectorGUIPermissionsVue
          :disabled="!(form.apps && form.apps.length)"
          :filterApps="form.apps"
          dark
          autoload
          label="Allowed Permissions"
          helpText="This is a set of permissions that are allowed under this role. <br> IF NOTHING SELECTED ALL ALLOWED"
          return-object
          v-model="form.permissions"
          multiple
        >
        </SelectorGUIPermissionsVue>

        <SelectorGUIPermissionsVue
          dark
          autoload
          label="UI Global Permissions"
          helpText="This is a set of global permissions that are allowed under this role. <br> IF NOTHING SELECTED ALL ALLOWED"
          return-object
          v-model="form.permissions"
          multiple
        >
        </SelectorGUIPermissionsVue>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
<script>
import SelectorGUIAppsVue from "../../../atoms/common/autocompletes/gui/SelectorGUIApps.vue";
import SelectorGUIPermissionsVue from "../../../atoms/common/autocompletes/gui/SelectorGUIPermissions.vue";
import SelectorGUIViewsVue from "../../../atoms/common/autocompletes/gui/SelectorGUIViews.vue";

import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],
      actions: [
        {
          name: "Create",
          description: "Will create new Tag",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorGUIAppsVue,
    SelectorGUIPermissionsVue,
    SelectorGUIViewsVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
    
    
    <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>